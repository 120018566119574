import React from 'react'
import { graphql } from 'gatsby'
//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/customer.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'
import customerPage from '../images/customer-pg.svg'

import customer1 from '../images/customer-s1.svg'
import customer2 from '../images/customer-s2.svg'
import customer3 from '../images/customer-s3.svg'
import customer4 from '../images/customer-s4.svg'
import customer5 from '../images/customer-s5.svg'
import customer6 from '../images/customer-s6.svg'
import customer7 from '../images/customer-s7.svg'
import customer8 from '../images/customer-s8.svg'
import customer9 from '../images/customer-s9.svg'
import customer10 from '../images/customer-s10.svg'
import customer11 from '../images/customer-s11.svg'

const Customer = ({ data }) => {
	const state = { productName: 'Customer Engagement', planTitle: 'Starter Plan', planPrice: '1 $' }
	const signupClick = () => {
		localStorage.setItem(
			'state',
			JSON.stringify({
				productName: state.productName,
				planTitle: state.planTitle,
				planPrice: state.planPrice,
			})
		)
	}
	return (
		<Layout>
			<SEO
				title="Customer Engagement"
				description="enable your customers to interact with your company in the best digitally engaging manner possible"
			/>
			<div className="customer-page">
				<div className="Header-parent">
					<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
				</div>
				<div className="page-content customer-content">
					<div className="customer-section">
						<div className="header-content">
							<div className="header-items">
								<h1>Customer Engagement</h1>
								<div className="hint">
									<h3>
										<span>Customer Engagement</span>
										<span>
											{' '}
											products enable your customers to interact
											with your company in the best digitally
											engaging manner possible.
										</span>
									</h3>
								</div>
								<CustomButton>
									<Link
										className="demo-request"
										data-track-location="idengager"
										to="/pricing"
									>
										Request Demo
									</Link>
								</CustomButton>
							</div>
							<div className="header-imgs">
								<img src={customerPage} alt="services-left" />
							</div>
						</div>
					</div>
					<section className="products-content" id="products-content">
						<h1 className="product-header">Explore Features</h1>
						<div className="products-container">
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">E-Commerce Support</h3>
										<p className="subtitle">
											Through this feature, users are able to
											manage their properties and pay for their
											installments, purchase accessories and
											spare parts and make the payments on the
											spot, at their convenience.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer1}
											className="item-img"
											alt="E-Commerce"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">Catalog View</h3>
										<p className="subtitle">
											Showcase your products, in the best,
											visually attractive manner, inside out,
											that your customers feel they can reach out
											and grab it.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer2}
											className="item-img"
											alt="Catalog"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">Service Request</h3>
										<p className="subtitle">
											Your customers can schedule maintenance
											requests at their convenience according to
											your availed time slots, no fuss, no mess.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer3}
											className="item-img"
											alt="Service"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">Marketing Features</h3>
										<p className="subtitle">
											With one click, you will be able to push
											notifications to all your customers with
											the latest offers and promotions.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer4}
											className="item-img"
											alt="Marketing"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">Personalized app</h3>
										<p className="subtitle">
											Everything is configurable from users
											interface to functionalities, content and
											application authorizations. So you will
											find no need to rebuild the app or make any
											additional development effort.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer5}
											className="item-img"
											alt="Personalized"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">Manage my Own Objects</h3>
										<p className="subtitle">
											Your customers can get and control their
											own objects by using this app , also they
											can get alerts regarding objects units from
											your back office
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer6}
											className="item-img"
											alt="Objects"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">Gallery View</h3>
										<p className="subtitle">
											Keep a huge database of all your products
											pictures, handy for any drop in customers
											to check out.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer7}
											className="item-img"
											alt="Gallery"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">Promotions</h3>
										<p className="subtitle">
											You can put your all classified , scheduled
											and interactive promotions to your target
											customers , its available with push
											notification features.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer8}
											className="item-img"
											alt="Promotions"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">Voice Messages</h3>
										<p className="subtitle">
											Allow your customers an even higher level
											to keeping in touch and easing their lifes,
											by having them record their feedbacks and
											suggestions over a voice note. Capture your
											customers voice, literally!
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer9}
											className="item-img"
											alt="Voice"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent reverse-parent">
									<div className="product-details">
										<h3 className="title">
											Supports Multi Levels of Users and
											Permissions.
										</h3>
										<p className="subtitle">
											Allow several levels of permissions per
											user, according to your own classifications
											of customers inside your enterprise
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer10}
											className="item-img"
											alt="Supports"
										/>
									</div>
								</div>
							</div>
							<div className="product-wrapper">
								<div className="product-parent">
									<div className="product-details">
										<h3 className="title">Branches</h3>
										<p className="subtitle">
											Browse companies locations and get
											direction to your branches.
										</p>
										<CustomButton>
											<Link
												className="product-btn"
												to="/signup"
												state={state}
												onClick={signupClick}
											>
												Get Trial
											</Link>
										</CustomButton>
									</div>
									<div className="product-img">
										<img
											src={customer11}
											className="item-img"
											alt="Branches"
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div className="footer-section">
					<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
				</div>
			</div>
		</Layout>
	)
}
export const pageQuery = graphql`
	query CustomerQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default Customer
